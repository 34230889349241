<div class="intro">
    <img class="intro-img img-fluid mb-3 mb-lg-0 rounded" style="opacity: 0.8;" src="assets/img/background1.png"
        alt="..." />
    <div class="intro-text left-0 text-center bg-faded p-5 rounded">
        <h1 class="section-heading mb-4">
            <span class="section-heading-upper">DWWM7 & CDA7</span>
            <span class="section-heading-lower">Projet Angular</span>
        </h1>
        <p class="mb-3">Équipe de développeurs travaillant main dans la main pour un projet autour d'Angular.
        </p>
        <div class="intro-button mx-auto"><a class="btn btn-xl" routerLink="trombi"
                style="background-color:#326b9a; color: #fff">Voir le trombinoscope</a></div>
    </div>
</div>