
<nav class="navbar navbar-expand-lg" id="mainNav">
    <div class="container-fluid">
        <a class="navbar-brand text-uppercase fw-bold d-lg-none" href="index.html">DWWM7 & CDA7</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fas fa-hamburger" style="color: #e6a756;"></i>
        </button>
                <div class="collapse navbar-collapse" id="navbarResponsive">
            <ul class="navbar-nav mx-auto">
                <li class="nav-item px-lg-4"><a class="nav-link text-uppercase" routerLink="">Accueil</a></li>
                <li class="nav-item px-lg-4"><a class="nav-link text-uppercase" routerLink="nasa">RocketLab</a></li>
                <li class="nav-item px-lg-4"><a class="nav-link text-uppercase" routerLink="ftp">Free to Play</a></li>
                <li class="nav-item px-lg-4"><a class="nav-link text-uppercase" routerLink="pollution">Pollution</a></li>
                <li class="nav-item px-lg-4"><a class="nav-link text-uppercase" routerLink="trombi">trombinoscope</a></li>
               </ul>
        </div>
    </div>
</nav>
